import React from "react";
import { Box, Flex } from "@nulogy/components";
import WorkOrderCardStatus from "./WorkOrderCardStatus";
import WorkOrderCardWrapper from "./WorkOrderCardWrapper";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import { formatDate, formatProductionRate } from "components/helpers/format";
import NotesIndicator from "components/pages/schedule/NotesIndicator";
import WorkOrderFlag from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkOrderFlag";
import LineTypePreferences from "components/pages/schedule/workOrderCards/LineTypePreferences";
import DraggableWorkOrderCard from "components/pages/schedule/workOrderCards/DraggableWorkOrderCard";
import WorkOrderCardHeader from "components/pages/schedule/workOrderCards/WorkOrderCardHeader";
import WorkOrderCardInformation from "components/pages/schedule/workOrderCards/WorkOrderCardInformation";
import ThinDivider from "components/common/ThinDivider";

export default function WorkOrderCard({ workOrder, expanded, onToggleExpandWorkOrder, layoutSettingData }) {
  const applicationContext = useApplicationContext();

  if (applicationContext.loading) return null;

  const hasLineTypePreference = workOrder.lineTypePreferencePrimary || workOrder.lineTypePreferenceSecondary;

  const layoutSetting = layoutSettingData.workOrderLayoutSetting;

  const expandedFields = [
    { label: "Work order ID", value: workOrder.externalId, settingKey: "workOrderId" },
    { label: "Work order description", value: workOrder.description, settingKey: "workOrderDescription" },
    {
      label: "Material availability date",
      value: formatDate(workOrder.materialAvailabilityDate),
      settingKey: "materialAvailabilityDate",
    },
    { label: "Planned start (from Shop Floor)", value: formatDate(workOrder.startAt), settingKey: "plannedStartDate" },
    { label: "Planned end (from Shop Floor)", value: formatDate(workOrder.endAt), settingKey: "plannedEndDate" },
    { label: "Custom work order field", value: workOrder.customWorkOrderFieldName, settingKey: "customWorkOrderField" },
    { label: "Reference 1", value: workOrder.reference1, settingKey: "reference1" },
    { label: "Reference 2", value: workOrder.reference2, settingKey: "reference2" },
    { label: "Reference 3", value: workOrder.reference3, settingKey: "reference3" },
    { label: "Item class", value: workOrder.itemClass?.name, settingKey: "itemClass" },
    { label: "Item family", value: workOrder.itemFamily?.name, settingKey: "itemFamily" },
    { label: "Item type", value: workOrder.itemType?.name, settingKey: "itemType" },
    { label: "Item category", value: workOrder.itemCategory?.name, settingKey: "itemCategory" },
    { label: "Service category", value: workOrder.serviceCategory?.name, settingKey: "serviceCategory" },
    {
      label: "Standard production rate",
      value: formatProductionRate({
        productionRate: workOrder.standardProductionRate,
        unitOfMeasure: workOrder.unitOfMeasure,
      }),
      settingKey: "standardProductionRate",
    },
    {
      label: "Recommended production rate",
      value: formatProductionRate({
        productionRate: workOrder.recommendedProductionRate,
        unitOfMeasure: workOrder.unitOfMeasure,
      }),
      settingKey: "recommendedProductionRate",
    },
    { label: "Standard people", value: workOrder.standardLabor, settingKey: "standardPeople" },
    { label: "Recommended people", value: workOrder.recommendedLabor, settingKey: "recommendedPeople" },
  ];

  return (
    <WorkOrderCardWrapper workOrderId={workOrder.id}>
      <DraggableWorkOrderCard workOrder={workOrder}>
        <WorkOrderCardStatus workOrder={workOrder} />
        {workOrder.notes && <NotesIndicator />}
        <Flex gap="x1" flexDirection="column">
          <WorkOrderCardHeader
            workOrder={workOrder}
            expandedFields={expandedFields}
            layoutSetting={layoutSetting}
            onToggleExpandWorkOrder={onToggleExpandWorkOrder}
            expanded={expanded}
          />
          <WorkOrderCardInformation
            workOrder={workOrder}
            expandedFields={expandedFields}
            layoutSetting={layoutSetting}
            expanded={expanded}
          />

          {(workOrder.flag || hasLineTypePreference) && (
            <Box>
              <ThinDivider mt="0" />
              <Flex flexDirection="row" alignItems="center">
                <WorkOrderFlag flag={workOrder.flag} />
                <LineTypePreferences workOrder={workOrder} />
              </Flex>
            </Box>
          )}
        </Flex>
      </DraggableWorkOrderCard>
    </WorkOrderCardWrapper>
  );
}
