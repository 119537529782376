import React from "react";
import styled from "styled-components";
import { Box, Button, QuietButton, Select, Sidebar, theme } from "@nulogy/components";
import SidebarExpander from "./SidebarExpander";
import VirtualizedWorkOrderList from "./VirtualizedWorkOrderList";
import VirtualizedWorkOrderListOld from "./VirtualizedWorkOrderListOld";
import SearchInput from "components/controls/SearchInput";
import ApolloLoadingAnimation from "components/common/ApolloLoadingAnimation";
import ReactExt from "utils/ReactExt";

const EditFiltersButton = styled(Button)`
  vertical-align: bottom;
`;

const ClearFiltersButton = styled(QuietButton)`
  float: right;
`;

const FilterCountIndicator = styled.span`
  padding-left: 8px;
  font-size: ${theme.fontSizes.small};
`;

const SearchCategorySelect = styled(Select)`
  .ndsSelect__control {
    border-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  input {
    border-top: 0 !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`;

function SidebarView({
  workOrders,
  onTextSearch,
  onCategoryChange,
  onClearFilters,
  numberOfFiltersApplied,
  slideOutState,
  onToggleSidebar,
  sidebarOpen,
  searchText,
  searchCategory,
  fetching,
  searchCategoryOptions,
  applicationContext,
}) {
  const handleWorkOrderSearch = (event) => {
    const newSearchText = event?.target?.value;
    if (newSearchText === searchText) return;

    onTextSearch({ searchText: newSearchText, searchCategory });
  };

  const handleSearchCategory = (newSearchCategory) => {
    if (newSearchCategory === searchCategory) return;

    onCategoryChange({ searchText, searchCategory: newSearchCategory });
  };

  const filtersAppliedText = () => {
    if (numberOfFiltersApplied === 0) return "";

    return numberOfFiltersApplied === 1
      ? `${numberOfFiltersApplied} filter applied`
      : `${numberOfFiltersApplied} filters applied`;
  };

  const handleClickEditFilters = () => {
    if (slideOutState.isFilterMode()) {
      slideOutState.closeSlideOut();
    } else {
      slideOutState.openFilterSidebar();
    }
  };

  const width = `${applicationContext.settings.customizeWorkOrderCards ? 408 : 400}px`;

  return (
    <>
      <Sidebar
        isOpen={sidebarOpen}
        height="100%"
        onClose={onToggleSidebar}
        closeOnOutsideClick={false}
        overlay={false}
        closeButtonTestId="spec-close-sidebar"
        style={{ zIndex: 10 }}
        title="Work orders"
        width={width}
      >
        <SearchCategorySelect options={searchCategoryOptions} value={searchCategory} onChange={handleSearchCategory} />
        <StyledSearchInput
          mb="x1"
          defaultValue={searchText}
          onChange={handleWorkOrderSearch}
          className="spec-filter-field searchFilterInput pendo-search-filter"
          placeholder="Search all work orders..."
        />
        <Box mb="x1">
          <EditFiltersButton size="small" onClick={handleClickEditFilters}>
            Edit filters
          </EditFiltersButton>
          <FilterCountIndicator data-testid="filters-applied" className="spec-filters-applied">
            {filtersAppliedText()}
          </FilterCountIndicator>
          <ClearFiltersButton size="small" onClick={onClearFilters}>
            Clear filters
          </ClearFiltersButton>
        </Box>
        {applicationContext.settings.customizeWorkOrderCards ? (
          <VirtualizedWorkOrderList workOrders={workOrders} />
        ) : (
          <VirtualizedWorkOrderListOld workOrders={workOrders} />
        )}
        <ApolloLoadingAnimation fetching={fetching} />
      </Sidebar>
      {!sidebarOpen && <SidebarExpander onToggleSidebar={onToggleSidebar} />}
    </>
  );
}

export default React.memo(SidebarView, ReactExt.checkPropsForReRender);
