import React, { useEffect } from "react";
import styled from "styled-components";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { useQuery } from "@apollo/client";
import omit from "lodash/omit";
import WorkOrderCard from "./workOrderCards/WorkOrderCard";
import MockWorkOrderCardContainer from "./workOrderCards/MockWorkOrderCardContainer";
import MoreResultsMessage from "./MoreResultsMessage";
import NoResultsFound from "./NoResultsFound";
import { SIDE_BAR_LIMIT } from "config/appConfig";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import Authorization from "policies/Authorization";
import { GetLayoutSetting } from "hooks/apollo/customerSettings/customerSettings.gql";

const FULL_PAGE_INCLUDING_FOOTER = SIDE_BAR_LIMIT + 1;
const CARD_HEIGHT_INCLUDING_PADDING = 161;
const FIXED_ELEMENT_SIZES_WITH_PADDING = 80;
const cache = new CellMeasurerCache({
  minHeight: FIXED_ELEMENT_SIZES_WITH_PADDING,
  fixedWidth: true,
});

const Wrapper = styled.div`
  height: ${({ searchBarHeight }) => `calc(100% - ${searchBarHeight + 36}px)`};

  /*
   * This fixes a bug where the context menu gets cut off if there are only a few
   * work orders in the sidebar.  This appears to be an issue for several folks
   * out there but, unfortunately, the creators of react-virtualized-list don't
   * seem to care too much about fixing this.
   *
   * https://github.com/bvaughn/react-virtualized/issues/876
  */
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

export default function VirtualizedWorkOrderList({ workOrders }) {
  const applicationContext = useApplicationContext();
  const policy = Authorization.usePolicy();
  const [expandedWorkOrders, setExpandedWorkOrders] = React.useState([]);
  const { data: layoutSettingData, loading: layoutSettingLoading } = useQuery(GetLayoutSetting);

  const ListRef = React.createRef();

  useEffect(() => cache.clearAll(), [workOrders]);

  const onToggleExpandWorkOrder = (workOrderId) => {
    if (expandedWorkOrders.includes(workOrderId)) {
      setExpandedWorkOrders(expandedWorkOrders.filter((id) => id !== workOrderId));
    } else {
      setExpandedWorkOrders([...expandedWorkOrders, workOrderId]);
    }

    if (ListRef.current) {
      ListRef.current.recomputeRowHeights();
      ListRef.current.forceUpdate();
    }
  };

  const rowHeight = ({ index }) => {
    const workOrder = workOrders[index];
    if (!workOrder) return 0;

    const hasLinePreferences = getHasLinePreferences(workOrder);

    const alwaysVisibleFieldCount = getAlwaysVisibleFieldCount(layoutSettingData);
    const expandableFieldCount = getExpandableFieldCount(layoutSettingData);

    const allFieldsCount = alwaysVisibleFieldCount + expandableFieldCount;
    const visibleFieldCount = expandedWorkOrders.includes(workOrder.id) ? allFieldsCount : alwaysVisibleFieldCount;
    const dividerCount = hasLinePreferences || workOrder.flag ? 2 : 1;
    const errorCount = (workOrder.standardProductionRate <= 0 ? 1 : 0) + (workOrder.quantity <= 0 ? 1 : 0);

    const dividersHeight = dividerCount * 17;
    const fieldsHeight = visibleFieldCount * 18;
    const linePrefHeight = hasLinePreferences ? 24 : 0;
    const flagsHeight = workOrder.flag ? 20 : 0;
    const errorFieldHeight = errorCount ? -18 + errorCount * 18 : 0;
    const flagsAndLinePrefHeight = Math.max(linePrefHeight, flagsHeight);

    return FIXED_ELEMENT_SIZES_WITH_PADDING + dividersHeight + fieldsHeight + flagsAndLinePrefHeight + errorFieldHeight;
  };

  if (layoutSettingLoading) return null;

  return (
    <Wrapper searchBarHeight={126} className="spec-side-bar">
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            rowCount={getRowCount(workOrders)}
            estimatedRowSize={CARD_HEIGHT_INCLUDING_PADDING}
            rowHeight={rowHeight}
            rowRenderer={rowRenderer({
              workOrders,
              applicationContext,
              policy,
              expandedWorkOrders,
              onToggleExpandWorkOrder,
              ListRef,
              layoutSettingData,
            })}
            noRowsRenderer={NoResultsFound}
            width={width}
            ref={ListRef}
            overscanRowCount={0}
            deferredMeasurementCache={cache}
            style={{ outline: "none" }}
          />
        )}
      </AutoSizer>
    </Wrapper>
  );
}

const getAlwaysVisibleFieldCount = (layoutSettingData) => {
  const { itemCode, itemDescription } = layoutSettingData.workOrderLayoutSetting;
  const productionFields = 3;
  const alwaysVisibleFields = 1;

  return [itemCode, itemDescription].filter((val) => val === true).length + productionFields + alwaysVisibleFields;
};

const getExpandableFieldCount = (layoutSettingData) => {
  const expandedFieldSettings = omit(layoutSettingData.workOrderLayoutSetting, [
    "itemCode",
    "itemDescription",
    "__typename",
  ]);

  return Object.values(expandedFieldSettings).filter((val) => val === true).length;
};

const getHasLinePreferences = (workOrder) =>
  workOrder.lineTypePreferencePrimary || workOrder.lineTypePreferenceSecondary;

function getRowCount(workOrders) {
  if (workOrders?.length === SIDE_BAR_LIMIT) {
    return FULL_PAGE_INCLUDING_FOOTER;
  }

  return workOrders?.length;
}

function rowRenderer({
  workOrders,
  applicationContext,
  policy,
  expandedWorkOrders,
  onToggleExpandWorkOrder,
  ListRef,
  layoutSettingData,
}) {
  return ({ index, style, isScrolling, parent, key }) => {
    const workOrder = workOrders[index];
    const atPageLimit = index === SIDE_BAR_LIMIT;

    if (atPageLimit) {
      return (
        <CellMeasurer cache={cache} key="-1" parent={parent} rowIndex={index}>
          {() => (
            <div style={{ ...style, paddingBottom: "4px" }} key={key}>
              <MoreResultsMessage />
            </div>
          )}
        </CellMeasurer>
      );
    }

    return (
      <CellMeasurer cache={cache} key={workOrder.id} parent={parent} rowIndex={index}>
        {() => (
          <div style={{ ...style, padding: "16px 4px 0 4px" }}>
            {isScrolling ? (
              <MockWorkOrderCardContainer
                applicationContext={applicationContext}
                expandedWorkOrders={expandedWorkOrders}
                layoutSettingData={layoutSettingData}
                policy={policy}
                workOrder={workOrder}
              />
            ) : (
              <WorkOrderCard
                workOrder={workOrder}
                expanded={expandedWorkOrders.includes(workOrder.id)}
                onToggleExpandWorkOrder={onToggleExpandWorkOrder}
                listRef={ListRef}
                layoutSettingData={layoutSettingData}
              />
            )}
          </div>
        )}
      </CellMeasurer>
    );
  };
}
